<template>
  <div class="m1">
    <div class="hd ">
      <div class="h1">
        入库列表
      </div>
    </div>

    <div style="height: 7.4rem; font-size: 1.6rem; cursor: pointer;">
      <span
        style="display: inline-block; width: 10rem; height: 5.4rem; margin-left: 1rem; margin-right: 3rem; line-height: 5.4rem; text-align: center;"
        @click="Rkgl(0)" :class="cekd? 'b_w':'b_x'">
        环境设备
      </span>
      <span style="display: inline-block; width: 10rem; height: 5.4rem; line-height: 5.4rem; text-align: center;"
        @click="Rkgl(1)" :class="!cekd? 'b_w':'b_x'">
        能源设备
      </span>
    </div>
    <div class="ctl_wrapper">
      <div class="search_wrapper">
        <span class="lable_text">设备类型:</span>
        <div class="equipment_selecter">
          <el-select class="ass-select" v-model="obj.equipmentId" clearable placeholder="选择设备类型" @change="changeEquipment"
            :popper-append-to-body="false">
            <el-option v-for="item in equipmentList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          </div>
          <span class="lable_text">关联状态:</span>
          <div class="sta_selecter">
            <template>
              <el-select class="ass-select" v-model="assSta" clearable placeholder="选择是否关联" @change="changeAss"
                :popper-append-to-body="false">
                <el-option v-for="item in assStatus" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </template>
          </div>
          <span class="lable_text">设备编号:</span>
          <div>
            <el-input v-model="obj.sn" autocomplete="off" clearable></el-input>
          </div>
          <div class="h2 search_btn" @click="info()">
            <i class="el-icon-search"></i>&nbsp;查询
          </div>
      </div>


      <div class="h2" @click="addN()">
        <i class="el-icon-plus"></i>&nbsp;新增入库
      </div>
    </div>

    <!-- 列表 -->
    <div class="lb">

      <div class="hd bg">
        <!-- 账号密码 -->
        <div>
          <span class="hd_1">
            选择
          </span>
          <span class="hd_2" style="width: 12.7%;">
            <div style="margin-left: 1rem;">
              设备名称
            </div>
          </span>
          <span class="hd_2" style="width: 17%;">
            <div style="margin-left: 1rem;">
              设备编号
            </div>
          </span>
          <span class="hd_2" style="width: 14%;">
            <div style="margin-left: 1rem;">
              RFID
            </div>
          </span>
          <!--          <span class="hd_2" style="width: 8.5%;">
            <div style="margin-left: 1rem;">
              规格型号
            </div>
          </span> -->
          <span class="hd_2" style="width: 7.7%;">
            <div style="margin-left: 1rem;">
              生产日期
            </div>
          </span>
          <span class="hd_2" style="width: 9.9%;">
            <div style="margin-left: 1rem;">
              生产厂家
            </div>
          </span>
          <span class="hd_2" style="width: 5.3%;">
            <div style="margin-left: 1rem;">
              RFID标签
            </div>
          </span>
          <span class="hd_2" style="width: 7.7%;">
            <div style="margin-left: 1rem;">
              设备关联状态
            </div>
          </span>
          <span class="hd_2" style="width: 7.7%;">
            <div style="margin-left: 1rem;">
              RFID停用/启用
            </div>
          </span>
          <!-- <span class="hd_2" style="width: 19.1%;">-->
          <span class="hd_2" style="width: 10%;">
            <div style="text-align: center;">
              操作
            </div>
          </span>
        </div>
      </div>

      <!-- 主体 -->
      <div style="position: relative;">
        <div class="main" v-for="(item,index) in Data" :key="index" :class="index % 2 == 0?'bg1':'bg2'">
          <div style="width: 100%;display: flex;">
            <span class="m_1">
              <el-checkbox v-model="item.checked"></el-checkbox>
            </span>
            <span class="m_2 z1" style="width: 12.7%;">
              <div style="margin-left: 1rem;overflow: hidden;white-space: nowrap; text-overflow: ellipsis;">
                {{item.name}}
              </div>
              <div class="hidden-text">{{item.name}}</div>
            </span>
            <span class="m_2 z1" style="width: 17%;">
              <div style="margin-left: 1rem; overflow: hidden;white-space: nowrap; text-overflow: ellipsis; ">
                {{item.serialNumber}}
              </div>
              <div class="hidden-text">{{item.serialNumber}}</div>
            </span>
            <span class="m_2 z1" style="width: 14%;">
              <div style="margin-left: 1rem; overflow: hidden;white-space: nowrap; text-overflow: ellipsis; ">
                {{item.rfid}}
              </div>
              <div class="hidden-text">{{item.rfid}}</div>
            </span>
            <!--            <span class="m_2 z1" style="width: 8.5%;">
              <div style="margin-left: 1rem;overflow: hidden;white-space: nowrap; text-overflow: ellipsis; ">
                {{item.specifications}}
              </div>
              <div class="hidden-text">{{item.specifications}}</div>
            </span> -->
            <span class="m_2" style="width: 7.7%;">
              <div style="margin-left: 1rem;">
                {{item.productionData}}
              </div>
            </span>
            <span class="m_2 z1" style="width: 9.9%;">
              <div style="margin-left: 1rem;overflow: hidden;white-space: nowrap; text-overflow: ellipsis; ">
                {{item.manufactor}}
              </div>
              <div class="hidden-text">{{item.manufactor}}</div>
            </span>
            <span class="m_2" style="width: 5.3%; text-align: center; ">

              <img v-if="item.qrCode != '' && item.qrCode != null" style="width: 3rem;  cursor: pointer;
                transition: all 0.6s; margin-top: .5rem;" :src="item.qrCode">

            </span>
            <span class="m_2" style="width: 7.7%; text-align: center;">
              <!-- <div style="margin-left: 1rem;">
                {{gl[item.associationStatus]}}
              </div> -->
              {{gl[item.associationStatus]}}
            </span>
            <span class="m_2" style="width: 7.7%;text-align: center;">
              <el-switch style="margin-bottom: .5rem;" v-model="item.KG" @change="kg(item,index)"
                active-color="rgba(28, 184, 184, 1)" inactive-color="rgba(212, 214, 217, 0.6)">
              </el-switch>
            </span>
            <span class="m_2"  style="width: 10%;text-align: center;">
              <span style=" color: rgba(43, 222, 201, 0.6); cursor: pointer;"  @click="XG(item)">
                  RFID修改
              </span>
              <span  style=" color: red; margin-left: 1rem"  @click="delWarehousing(item.id)">
                  删除
              </span>
            </span>
            <!-- </span> -->
          </div>
        </div>
      </div>

    </div>

    <!-- 翻页 -->
    <div style="width: 100%; height: 5rem; padding: 1rem 0; margin-top: 4rem;">
      <!-- <span class="demonstration">完整功能</span> -->
      <el-pagination style="float: right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="currentPage4" :page-sizes="[10, 20, 30, 40]" :page-size="10"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>

    <!-- 新增弹窗/修改 -->
    <el-dialog title="操作" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
      <el-form :model="form">
        <el-form-item label="规格型号:" :label-width="formLabelWidth">
          <el-input v-model="form.specifications" autocomplete="off" disabled="disabled"></el-input>
        </el-form-item>
        <el-form-item label="生产厂家:" :label-width="formLabelWidth">
          <el-input v-model="form.manufactor" autocomplete="off" disabled="disabled"></el-input>
        </el-form-item>
        <!--        <el-form-item label="设备名字:" :label-width="formLabelWidth">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item> -->
        <el-form-item label="生产日期:" :label-width="formLabelWidth">
          <!-- <el-input v-model="form.productionData" autocomplete="off"></el-input> -->
          <el-date-picker v-model="form.productionData" type="date" placeholder="选择日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="RFID:" :label-width="formLabelWidth">
          <el-input v-model="form.rfid" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item v-if="form.associationStatus==0" label="设备编号:" :label-width="formLabelWidth">
          <el-input v-model="form.serialNumber" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item v-if="form.associationStatus==1" label="设备编号:" :label-width="formLabelWidth">
          <el-input v-model="form.serialNumber" autocomplete="off" disabled="disabled"></el-input>
        </el-form-item>
        <el-form-item label="设备类别:" :label-width="formLabelWidth">
          <el-radio-group v-model="form.type" @change="getEquipment()">
            <el-radio :label="0">环境设备</el-radio>
            <el-radio :label="1">能源设备</el-radio>
          </el-radio-group>
        </el-form-item>
        <div style="margin-left:12rem ;">
          <el-select v-model="value" placeholder="请选择设备类型" @change="xsid">
            <el-option v-for="item in tableData" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div style="display: flex;font-size: 14px;margin-top:22px ;">
          <div style="display: flex;justify-content: flex-end;width:12rem ;">
            <span style="display:flex;">设备属性：</span>
          </div>
          <div style="display: flex;flex-wrap: wrap;">
            <span style="margin-left: 2rem;color: teal;" v-for="(item,index) in list1">{{item}};</span>
          </div>
        </div>

      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button v-if="form_qr" type="primary" @click="Form()">确 认</el-button>
        <el-button v-if="!form_qr" type="primary" @click="Form1()">修 改</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'gsgly',
    data() {
      return {
        assSta: '',
        assStatus: [{
            "id": 0,
            "name": '未关联'
          },
          {
            "id": 1,
            "name": '已关联'
          }
        ],
        cekd: true,
        formLabelWidth: '12rem',
        form_qr: true,
        form: {
          "manufactor": '',
          "name": "",
          "productionData": "",
          "rfid": "",
          "serialNumber": "",
          "specifications": "",
          "associationStatus": 0,
          "type": 0
        },
        equipmentList:[],
        pcount: null,
        dialogFormVisible: false,
        total: null,
        gl: ['未关联', '已关联'],
        Data: [],
        checked: true,
        value: '',
        currentPage4: 1,
        obj: {
          "page": 1,
          "size": 10,
          "type": 0,
          "associationStatus": '',
          "sn": '',
          "equipmentId":''
        },
        tableData: [],
        list1: []
      }
    },
    created() {
      this.info()
      this.Rkgl(0)
    },

    watch: {
      nyhj(val) {
        this.getEquipment()
      }
    },
    methods: {
      xsid(res) {
        this.list1 = []
        this.$newGet('/warehousing/equipment/parameter/list/' + this.value).then(res => {
          let para = ''
          res.data.forEach((item, index) => {
            para += item + ','
            this.list1.push(
              item
            )
          })
          this.form.parameter = para.replace(/,$/i, '')
        })
        this.tableData.forEach((item, index) => {
          if (item.id == res) {
            this.form.name = item.name
            this.form.manufactor = item.manufactor
            this.form.specifications = item.specifications
          }
        })
      },
      // 分页查询设备类型列表
      getEquipment() {
        this.value = ''
        this.form.parameter = ''
        this.form.name = ''
        var obj = {
          "page": 1,
          "size": 100,
          "type": this.form.type
        }
        this.$newPost('/equipment/type/page', obj).then(res => {
          if (res != null) {
            this.tableData = res.data.records
          }
        })
      },
      //切换关联状态
      changeAss(res) {
        this.obj.associationStatus = res
        // this.info()
      },
      changeEquipment(res) {
        this.obj.equipmentId = res
      },
      Rkgl(id) {
        this.obj.sn=''
        this.obj.equipmentId=''

        this.obj.type = id
        var obj = {
          "page": 1,
          "size": 100,
          "type": id
        }
        this.$newPost('/equipment/type/page', obj).then(res => {
          if (res != null) {
            this.equipmentList = res.data.records
          }
        })

        this.info()
        if (id == 0) {
          this.cekd = true
        } else {
          this.cekd = false
        }
      },
      Form1() {
        this.form.name = null
        this.form.parameter = null
        this.form.specifications = null
        console.log(this.form)


        this.form.equipmentTypeId = this.value
        // this.form.parameter = JSON.stringify(this.list1)
        this.$ajax('edrk', 'post', this.form).then(res => {
          if (res.code == 2000) {
            this.info()
            this.form = {
              "manufactor": '',
              "name": "",
              "productionData": "",
              "rfid": "",
              "serialNumber": "",
              "specifications": "",
              "associationStatus": 0,
              "type": null,
              'parameter': '',
              'equipmentTypeId': null
            }
            this.dialogFormVisible = false
            this.$message({
              type: 'success',
              message: res.message
            });
          } else {
            this.$message({
              type: 'error',
              message: res.message
            });
          }
        })
      },
      addN() {
        this.form = {
          "manufactor": '',
          "name": "",
          "productionData": "",
          "rfid": "",
          "serialNumber": "",
          "specifications": "",
          "associationStatus": 0,
          "type": null,
          'parameter': '',
          'equipmentTypeId': null
        }
        this.value = null
        this.list1 = []
        this.form_qr = true
        this.dialogFormVisible = true
        this.getEquipment()

      },
      XG(item) {

        this.form = {
          "id": item.id,
          "manufactor": item.manufactor,
          "name": item.name,
          "productionData": item.productionData,
          "rfid": item.rfid,
          "serialNumber": item.serialNumber,
          "specifications": item.specifications,
          "type": item.type,
          "parameter": item.parameter,
          "equipmentTypeId": item.equipmentTypeId,
          "associationStatus": item.associationStatus
        }



        this.getEquipment()
        let nadd = []
        if (item.parameter != null && item.parameter != '') {
          nadd = item.parameter.split(",");
        }

        this.list1 = nadd
        this.value = item.equipmentTypeId
        this.form_qr = false
        this.dialogFormVisible = true
      },
      Form() {
        this.form.equipmentTypeId = this.value
        this.$ajax('addrk', 'post', this.form).then(res => {
          console.log(res);
          if (res.code == 2000) {
            this.info()
            this.form = {
              "manufactor": '',
              "name": "",
              "productionData": "",
              "rfid": "",
              "serialNumber": "",
              "specifications": "",
              "type": null,
              'parameter': '',
              'equipmentTypeId': null,
              "associationStatus": 0,
            }
            this.dialogFormVisible = false
            this.$message({
              type: 'success',
              message: res.message
            });
          } else {
            this.$message({
              type: 'error',
              message: res.message
            });
          }
        })
      },
      kg(item, id) {
        // this.Data[id].KG = !this.Data[id].KG
        if (item.KG) {
          item.state = 0
        } else {
          item.state = 1
        }
        let obj = {
          "id": item.id,
          "state": item.state
        }
        console.log(obj);
        this.$ajax('ztrk', 'post', obj).then(res => {
          if (res.code == 2000) {
            this.info()
            this.$message({
              type: 'success',
              message: res.message
            });
          } else {
            this.$message({
              type: 'error',
              message: res.message
            });
          }
        })
      },
      delWarehousing(id) {
        this.$confirm('此操作将删除, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$newDelete("/warehousing/equipment/warehousing/" + id).then(res => {
            if (res.success) {
              this.$message({
                message: res.data,
                type: 'success'
              })
              this.info()
            } else {
              this.$message({
                message: res.data,
                type: 'error'
              })
            }

          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消删除'
          });
        });

      },
      qiyong(id) {
        if (id == 0) {
          return true
        } else {
          return false
        }
      },
      To() {
        this.$router.push('/gjsz_5')
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.obj.size = val
        this.info()

      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.obj.page = val
        this.info()
      },
      xian(item) {
        item.qr = !item.qr
      },
      info() {
        this.$ajax('rkgl', 'post', this.obj).then(res => {
          console.log(res, '列表');
          this.total = res.data.total
          this.Data = res.data.records
          this.pcount = res.data.pages
          this.Data.forEach(item => {
            this.$set(item, 'checked', false)
            if (item.state == 0) {
              this.$set(item, 'KG', true)
            } else {
              this.$set(item, 'KG', false)
            }
          })
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .bg {
    background-color: rgba(43, 201, 222, 0.6);
  }

  .bg1 {
    background-color: rgba(162, 162, 162, 0.2);
  }

  .bg2 {
    background: rgba(14, 242, 242, 0.3);
  }

  .b_w {
    color: rgba(43, 201, 222, 1);
    border-bottom: 1px solid rgba(43, 201, 222, 1);
  }

  .b_x {
    color: rgba(43, 222, 201, 0.6);
  }

  .m1 {
    position: relative;
    // top: 2rem;
    // right: 1rem;
    // left: 1rem;
    // bottom: 0rem;
    color: #FFFFFF;
    margin: 1rem 0 0 .1rem;
    width: 156.6rem;

    // height: 59.4rem;

    .hd {
      // position: relative;
      width: 100%;
      height: 5rem;


      .h1 {
        display: inline-block;
        // width: 11.5rem;
        height: 5rem;
        line-height: 5rem;
        padding-left: 1rem;
        font-size: 1.8rem;
      }


    }

    .lb {
      position: relative;
      // position: absolute;
      // left: 1rem;
      // top: 16.4rem;
      // right: 1rem;
      // bottom: 1rem;
      // border: 1px solid #2BDEC9;
      margin-top: 1rem;
      font-size: 1.4rem;
      // overflow: auto;

      .hd {
        width: 100%;
        height: 3.6rem;
        line-height: 3.6rem;
        // padding-left: 1rem;
        font-size: 1.4rem;

        // border-bottom: 1px solid #2BDEC9;
        .hd_1 {
          display: inline-block;
          width: 4%;
          height: 4.2rem;
          line-height: 4.2rem;
          text-align: center;
          margin-right: .3%;
          // background-color: rgba(43, 201, 222, 0.6);
        }

        .hd_2 {
          display: inline-block;
          width: 94.5%;
          height: 4.2rem;
          line-height: 4.2rem;
          // background-color: rgba(43, 201, 222, 0.6);
          margin-right: .3%;
        }

        .zh {
          margin-left: 1rem;
          display: inline-block;
          width: 16.8rem;
          // overflow: hidden;
        }
      }

      .hd:last-child {
        // border-bottom: none;
      }

      .qx {
        position: absolute;
        top: .1rem;
        left: 50rem;
        display: inline-block;

        .lst {
          display: inline-block;
          width: 6.6rem;
          height: 2.6rem;
          line-height: 2.6rem;
          background-color: rgba(43, 201, 222, 0.6);
          text-align: center;
          border-radius: .3rem;
          margin-right: 1rem;
        }
      }

      .xg {
        position: absolute;
        top: .5rem;
        right: 2rem;
        width: 27rem;
        height: 2.6rem;
        line-height: 2.6rem;

        .gg {
          display: inline-block;
          width: 6.6rem;
          height: 2.6rem;
          line-height: 2.6rem;
          text-align: center;
          background-color: rgba(28, 184, 184, 1);
          border-radius: .4rem;
          margin-left: 1rem;
        }
      }
    }

    .main {
      // position: relative;
      width: 100%;
      // height: 4.2rem;
      // margin-top: 1rem;
      padding: .5rem 0;

      .m_1 {
        display: inline-block;
        width: 4%;
        height: 4.2rem;
        line-height: 4.2rem;
        text-align: center;
        margin-right: .3%;
        // border: 1px solid rgba(43, 201, 222, 0.6);
        box-sizing: border-box;
      }

      .m_2 {
        display: inline-block;
        width: 94.5%;
        height: 4.2rem;
        line-height: 4.2rem;
        // border: 1px solid rgba(43, 201, 222, 0.6);
        box-sizing: border-box;
        margin-right: .3%;
        // overflow: hidden;
      }

      .z1 {

        .hidden-text {
          font-size: 1.7rem;
          position: relative;
          line-height: 2.2rem;
          z-index: -1;
          top: -4.2rem;
          padding: 8px 8px 8px 8px;
          border-radius: .2rem;
        }
      }

      .z1:hover .hidden-text {
        word-wrap: break-word;
        background-color: rgba(8, 157, 157, 1);
        z-index: 999;
      }

      .m_2 img:hover {
        transform: scale(10);
        z-index: 999;
      }

      .m_3 {
        position: relative;
        box-sizing: border-box;
        float: right;
        width: 94.5%;
        height: 4.2rem;
        // border: 1px solid rgba(43, 201, 222, 0.6);
        box-sizing: border-box;
        margin-top: .5rem;
        line-height: 4.2rem;

        .zh {
          margin-left: 1rem;
          display: inline-block;
          width: 16.8rem;
          // overflow: hidden;
        }

        .qx {
          position: absolute;
          top: .1rem;
          left: 50rem;
          display: inline-block;

          .lst {
            display: inline-block;
            width: 6.6rem;
            height: 2.6rem;
            line-height: 2.6rem;
            background-color: rgba(43, 201, 222, 0.6);
            text-align: center;
            border-radius: .3rem;
            margin-right: 1rem;
          }
        }

        .xg {
          position: absolute;
          top: .5rem;
          right: 2rem;
          width: 27rem;
          height: 2.6rem;
          line-height: 2.6rem;

          .gg {
            display: inline-block;
            width: 6.6rem;
            height: 2.6rem;
            line-height: 2.6rem;
            text-align: center;
            background-color: rgba(28, 184, 184, 1);
            border-radius: .4rem;
            margin-left: 1rem;
          }
        }
      }

      .m_3:last-child {
        margin-bottom: 1rem;
      }
    }

    .main:hover {
      background-color: rgba(117, 117, 117, 0.4);
    }
  }

  .ctl_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .search_wrapper{
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      .lable_text{
        display: flex;
        font-size: 1.4rem;
        margin-left: 1rem;
        margin-right: .5rem;
      }
      .equipment_selecter{
        display: flex;
      }

      .sta_selecter {
        display: flex;

      }
      .search_btn{
        margin-left: 1rem;
        width: 8rem;
        color: rgba(17, 102, 89, 1.0);
        background-color: rgba(255, 255, 255,1);
      }

    }


    .h2 {
      margin-right: 1rem;
      width: 10.5rem;
      height: 3.8rem;
      background-color: rgba(14, 242, 242, 0.3);
      line-height: 3.8rem;
      text-align: center;
      border-radius: .5rem;
      font-size: 1.4rem;
    }
  }

  .ass-select {

    ::v-deep .el-select {
      width: 12.5rem;
      height: 3.125rem;

    }

    ::v-deep .el-input__inner {
      background-color: rgba(14, 242, 242, 0.3);
      color: #fff;
      border: 0px;
      font-size: 1.2rem;
    }

    ::v-deep .el-select-dropdown__item {
      color: #fff;
    }


    ::v-deep .el-scrollbar,
    ::v-deep .el-select-dropdown {
      background-color: #192534;
      color: #fff !important;
      border-color: rgba(14, 242, 242, 0.3);
    }

    ::v-deep .el-scrollbar__wrap,
    ::v-deep .el-select-dropdown__list {
      background-color: #192534;
      color: #fff !important;
    }

    ::v-deep .el-select-dropdown__item.hover,
    .el-select-dropdown__item:hover {
      background-color: rgba(14, 242, 242, 0.3);
      color: #fff;
    }

  }
</style>
